<template>
  <content-wrapper class="calendar-screen">
    <div class="calendar">
      <swiper
        :options="{slidesPerView: 1, touchRatio: 2, 
        navigation: {
          nextEl: '.next',
          prevEl: '.prev'
        }}"
        ref="calendar"
        @transitionStart="swipe()"
        v-if="calendar.length"
      >
        <swiper-slide v-for="(event, index) in calendar" :key="index">
          <calendar-event
            class="event"
            :title="event.title"
            :datetime="event.started_at"
            :text="event.description"
            :tags="event.additions"
            :img="event.images[Object.keys(event.images)[0]].replace('extra','medium')"
          />
        </swiper-slide>
      </swiper>
      <arrow class="next" d="right" @click.native="next()"></arrow>
      <arrow class="prev" d="left" @click.native="prev()"></arrow>
    </div>

    <swiper class="events" :options="{slidesPerView: 'auto', touchRatio: 2}">
      <swiper-slide v-for="event in events" :key="event.id">
        <event
          :title="event.title"
          :datetime="event.started_at"
          :place="event.city.name + (event.place ? ' • '+ event.place.title : '')"
          :img="event.images[Object.keys(event.images)[0]].replace('extra','small')"
          @click.native="goToEvent(event)"
        />
      </swiper-slide>
    </swiper>
  </content-wrapper>
</template>

<script>
// import { mapGetters } from "vuex";

import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import CalendarEvent from "@/components/Parts/CalendarEvent";
import Event from "@/components/Parts/EventHorisontalCard";
import Arrow from "@/components/Icons/ArrowsYellow";

export default {
  name: "CalendarScreen",
  components: { ContentWrapper, Event, CalendarEvent, Arrow },
  data() {
    return {
      calendar: [],
      currentDate: [new Date().getTime(), new Date().getTime()],
      tryCount: 3,
      events: [],
      currentTranslate: 0
    };
  },
  computed: {
    // ...mapGetters(["events"])
    swiper() {
      return this.$refs.calendar.swiper;
    }
  },
  mounted() {
    this.fetchCalendar(this.currentDate);
    this.fetchEvents();
  },
  methods: {
    fetchEvents() {
      api
        .getEvents([this.$moment().format("YYYY-MM-DD")], 10, 0, 1)
        .then(res => {
          this.events = res.data;
        });
    },
    fetchCalendar(dates, offset = null, count = 0) {
      return new Promise((resolve, reject) => {
        if (offset == "plus")
          this.currentDate = dates.map(date => date + 60 * 60 * 24 * 1000);
        if (offset == "minus")
          this.currentDate = dates.map(date => date - 60 * 60 * 24 * 1000);

        const datesStr = [
          this.$moment(this.currentDate[0]).format("YYYY-MM-DD"),
          this.$moment(this.currentDate[1]).format("YYYY-MM-DD")
        ];

        api.getEvents(datesStr, 1, 1).then(res => {
          if (res.data.length) {
            if (
              !this.calendar.length ||
              !this.calendar.filter(event => event.id == res.data[0].id).length
            ) {
              this.calendar.push(res.data[0]);
              resolve();
            } else reject(count);
          } else if (count < this.tryCount) {
            if (!offset || offset == "plus")
              this.fetchCalendar(
                this.currentDate,
                "plus",
                count + 1
              ).catch(count => reject(count));
            if (offset == "minus")
              this.fetchCalendar(
                this.currentDate,
                "minus",
                count + 1
              ).catch(count => reject(count));
          } else reject(count);
        });
      });
    },
    swipe() {
      if (event && (event.type == "pointerup" || event.type == "touchend")) {
        if (this.swiper.getTranslate() > this.currentTranslate) this.prev();
        if (this.swiper.getTranslate() < this.currentTranslate) this.next();
      }
    },
    async prev() {
      await this.fetchCalendar(this.currentDate, "minus")
        .then(() => {
          this.swiper.update();
          this.swiper.slidePrev();
          this.currentDate = this.currentDate.map(
            () => this.calendar[this.swiper.activeIndex].started_at * 1000
          );
        })
        .catch(() => {
          this.currentDate = this.currentDate.map(
            () => this.calendar[this.swiper.activeIndex].started_at * 1000
          );
        });
      setTimeout(() => {
        this.currentTranslate = this.swiper.getTranslate();
      }, 300);
    },
    async next() {
      await this.fetchCalendar(this.currentDate, "plus")
        .then(() => {
          this.swiper.update();
          this.swiper.slideNext();
          this.currentDate = this.currentDate.map(
            () => this.calendar[this.swiper.activeIndex].started_at * 1000
          );
        })
        .catch(() => {
          this.currentDate = this.currentDate.map(
            () => this.calendar[this.swiper.activeIndex].started_at * 1000
          );
        });
      setTimeout(() => {
        this.currentTranslate = this.swiper.getTranslate();
      }, 300);
    },
    goToEvent(event) {
      this.$router.push({ path: `/event/${event.id}` });
      this.$store.dispatch("setSelectedEvent", event);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.calendar-screen {
  .calendar {
    position: relative;
    height: 800px;
    margin: 0 32px;
    margin-top: 48px;
    margin-bottom: 80px;
    .swiper-slide {
      // margin-right: 32px;
      .event {
        transition-duration: 1s;
      }
    }

    .next {
      position: absolute;
      z-index: 10;
      width: 72px;
      height: 72px;
      top: 704px;
      right: 0;
    }
    .prev {
      position: absolute;
      z-index: 10;
      top: 704px;
      left: 0;
    }
  }
  .events {
    margin-bottom: 40px;
    overflow: visible;
    .swiper-slide {
      width: 633px;
      margin-left: 32px;
      margin-right: 24px;
      &:last-child {
        margin-right: 32px;
      }
    }
  }
}
</style>