<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      v-if="d=='right'"
    >
      <path
        fill="#5eD7f2"
        fill-rule="evenodd"
        d="M49.34 38.367L27.559 61.714l-4.416-4.733L42.656 36 23.143 15.02l4.416-4.734L49.34 33.633 51.429 36z"
      />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" v-else>
      <path
        fill="#5eD7f2"
        fill-rule="evenodd"
        d="M22.66 33.633l21.781-23.347 4.416 4.733L29.344 36l19.513 20.98-4.416 4.734L22.66 38.367 20.571 36z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    d: {
      type: String,
      default: "right"
    }
  }
};
</script>