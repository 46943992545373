<template>
  <div class="calendar-event">
    <div class="event">
      <div class="left-side">
        <h1>{{title}}</h1>
        <div class="image">
          <img :src="img" />
        </div>
      </div>
      <scrolled-content class="right-side" @scroll="onScroll">
        <div class="tags" v-if="tags.length">
          <tag :title="tag.value" v-for="tag in tags" :key="tag.id" />
        </div>

        <div class="text">{{text}}</div>
      </scrolled-content>
    </div>
    <div class="date">
      <div class="now" v-html="dates[0]" />
      <div class="old">{{ dates[1] }}</div>
    </div>
  </div>
</template>

<script>
import ScrolledContent from "@/components/Wrappers/ScrolledContent";
import Tag from "@/components/Parts/TagSmall";

export default {
  name: "CalendarEvent",
  components: { ScrolledContent, Tag },
  props: {
    img: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    datetime: {
      type: Number,
      default: 0
    },
    tags: {
      type: Array,
      default: null
    }
  },
  computed: {
    dates() {
      let now =
        this.$moment(this.datetime * 1000)
          .format("DD MMMM")
          .split(" ")[0] +
        " <span>" +
        this.$moment(this.datetime * 1000)
          .format("DD MMMM")
          .split(" ")[1] +
        "</span>";

      let oldDay = this.$moment(
        this.datetime * 1000 - 1000 * 60 * 60 * 24 * 13
      ).format("dddd");

      let old =
        this.$moment(this.datetime * 1000 - 1000 * 60 * 60 * 24 * 13).format(
          "DD MMMM ст. ст. • "
        ) +
        oldDay.charAt(0).toUpperCase() +
        oldDay.slice(1);

      return [now, old];
    }
  },
  methods: {
    onScroll() {
      this.showShade =
        event.target.scrollTop < event.target.offsetHeight ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.calendar-event {
  height: 800px;
  .event {
    display: flex;
    justify-content: space-between;
    height: 624px;
    .left-side {
      width: 488px;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      h1 {
        margin: 0;
        font-size: 50px;
        font-weight: bold;
        line-height: 1.12;
        color: rgba(#fff, 0.97);
      }
      .image {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-height: 314px;
        }
      }
    }
    .right-side {
      width: 488px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        width: 98%;
        height: 33px;
        background-image: linear-gradient(
          to bottom,
          rgba(36, 36, 35, 0) 0,
          $color_grey_2 100%
        );
      }

      .tags {
        margin-bottom: 20px;
        & .tag {
          margin-bottom: 12px;
          overflow: hidden;
        }
      }
      .text {
        color: rgba(#fff, 0.8);
        font-size: 24px;
        line-height: 1.5;
        letter-spacing: -0.1px;
        p {
          margin-bottom: 24px;
        }
        h2 {
          margin: 0;
          margin-bottom: 8px;
          font-size: 24px;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: -0.1px;
        }
      }
    }
  }
  .date {
    position: absolute;
    // margin: 0 auto;
    // margin-top: 56px;
    top: 680px;
    left: 132px;
    width: 752px;
    height: 120px;
    border-radius: 24px;
    border: solid 4px $color_yellow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .now {
      font-size: 40px;
      letter-spacing: -0.68px;
      color: $color_yellow;
      font-weight: 500;
      letter-spacing: -0.41px;
    }
    .old {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.68px;
      color: rgba(249, 213, 141, 0.72);
    }
  }
}
</style>
